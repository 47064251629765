import { useStore } from 'vuex'
import { computed } from 'vue'

export default function useElementRights(): (str: string) => boolean {
  const store = useStore()
  const rightsArr = computed(() => store.state.user.rightsArr)

  return (str: string) => {
    return (rightsArr.value ?? []).some((item) => item.menuUrl == str)
  }
}
