<template>
  <StyledModal :visible="visible" title="退款" @close="close" @submit="submit" width="700px" :submitLoading="submitLoading">
    <el-form ref="formRef" :model="formModel.form" label-width="100px">
      <el-form-item label="说明" prop="resultNote" :rules="[{ required: true, message: '请输入审核意见' }]">
        <el-input v-model="formModel.form.resultNote" type="textarea" placeholder="请输入..." />
      </el-form-item>
    </el-form>
  </StyledModal>
</template>

<script>
import { reactive, ref } from 'vue'
import StyledModal from '@/components/modal/StyledModal'
import request from '@/utils/request'
import { validateFormRef } from '@/common/utils_element'
import { ElMessage } from 'element-plus/lib/components'

const defaultForm = {
  resultNote: '',
}

export default {
  emits: ['success'],
  setup(_, ctx) {
    const visible = ref(false)
    const submitLoading = ref(false)

    const formRef = ref(null)
    const formModel = reactive({
      form: {
        ...defaultForm,
      },
    })

    const record = ref(null)

    function open(pRecord) {
      visible.value = true
      if (pRecord) {
        record.value = pRecord
      }
    }

    function close() {
      record.value = null
      formModel.form = { ...defaultForm }
      visible.value = false
    }

    async function submit() {
      await validateFormRef(formRef)
      const postData = {
        orderNo: record.value?.orderNo,
        ...formModel.form,
      }
      submitLoading.value = true
      request({
        url: '/web/system/doctororder/cancelDoctorOrderAdmin',
        data: postData,
      })
        .finally(() => {
          submitLoading.value = false
        })
        .then((data) => {
          ElMessage.success('操作成功')
          ctx.emit('success')
          close()
        })
    }

    return {
      visible,
      submitLoading,
      open,
      close,
      submit,
      record,
      formRef,
      formModel,
    }
  },
  components: { StyledModal },
}
</script>

<style lang="less" scoped>
.detailView_box {
  margin: 20px 0 0 100px;
}
</style>
