<template>
  <order-mng></order-mng>
</template>

<script>
import orderMng from '@/pages/order/orderMng/index.vue'
import needKeepAliveMixin from '@/mixins/needKeepAliveMixin'

export default {
  name: '/web/system/doctororder/ordermgr',
  ...needKeepAliveMixin,
  components: { orderMng },
}
</script>
